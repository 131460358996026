<template>
	<div class="contanier">
		<div class="filter-container title-border">
			<!-- 关键字搜索 -->
			<div class="recommend-number" style="margin-bottom: 20px;">
				<div class="item">共计：{{dataResult.Total }}人</div>
				<div class="item">直接推荐：{{dataResult.DirectRecommendCount }}人</div>
				<div class="item">间接推荐：{{dataResult.InDirectRecommendCount}}人</div>
			</div>
			<div class="filter-item">
				<label class="label">关键字:</label>
				<el-input v-model="searchKey" placeholder="手机号/微信昵称/姓名" style="width: 200px;"></el-input>
			</div>
			<!--销售类型  -->
			<div class="filter-item">
				<label class="label">推荐关系:</label>
				<el-select v-model="type" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:10px;width:90px;" @click="handleFilter">查询</el-button>
			</div>
		</div>
		<!-- table -->
		<div class="table-container content">
			<!-- :row-key="getRowKeys" -->
			<!-- @select='onTableSelect'  -->
			<el-table :data="tableDataList" style="width: 100%">
				<el-table-column label="推手">
					<template slot-scope="scope">
						<div style="display: flex;align-items: center;">
							<img :src="scope.row.WxHeadUrl ? scope.row.WxHeadUrl : defaultHeader" style="width: 50px;height: 50px;border-radius: 100%;">
							<div style="margin-left: 10px;height: 50px;line-height: 50px;">{{scope.row.Name ? scope.row.Name : (scope.row.WxNickname ? scope.row.WxNickname : scope.row.Phone)}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="Phone" label="手机号"></el-table-column>
				<el-table-column prop="DistributRoleName" label="分销级别"></el-table-column>
				<el-table-column prop="RecommendType" label="推荐关系"></el-table-column>
				<el-table-column prop="MemberDistributorAuthTime" label="授权时间"></el-table-column>
			</el-table>

			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size"
			 layout="total, sizes, prev, pager, next, jumper" :total="page.total"></el-pagination>
		</div>
	</div>
</template>
<script>
	import {
		memberdistributorrecommendlist
	} from '@/api/wyUsedInterface.js';
	import config from '@/config/index';
	export default {
		name: 'recommendDetail',
		props: {
			memberId: 0
		},
		data() {
			return {
				searchKey: '',
				type: null,
				typeOptions: [{
						label: '直接推荐',
						value: 1
					},
					{
						label: '间接推荐',
						value: 2
					}
				],
				page: {
					total: 0,
					current: 1,
					size: 10
				},
				tableDataList: [],
				checkedAll: false,
				multipleSelection: [],
				checkedShopPro: false,
				dataResult: {},
				imgUrl: config.IMG_BASE,
				defaultHeader: config.DEFAULT_HEADER
			};
		},
		created() {

		},
		beforeMount() {
			this.getRecommendList()
		},
		mounted() {},
		methods: {
			async getRecommendList() {
				try {
					let data = {
						MemberId: this.memberId,
						KeyWords: this.searchKey,
						RecommendType: this.type,
						Skip: (this.page.current - 1) * this.page.size,
						Take: this.page.size,
					}
					let result = await memberdistributorrecommendlist(data);
					this.dataResult = result.Result
					this.tableDataList = result.Result.Results;
					this.page.total = result.Result.Total;
				} catch (e) {
					//TODO handle the exception
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				console.log(val, 123)
				this.page.size= val;
				this.getRecommendList();
			},
			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page.current = val;
				this.getRecommendList();
			},
			//关闭弹框，以及曝光选择的列表
			saveSelectProList() {},
			//查询
			handleFilter() {
				this.page.current = 1
				this.getRecommendList()
			}
		}
	};
</script>
<style lang="less" scoped>
	.contanier {
		position: relative;
		overflow: hidden;
	}

	.content {
		overflow: hidden;
	}

	.dialog-footer {
		margin-top: 20px;
		text-align: center;

		.button {
			width: 150px;
		}
	}

	.recommend-number {
		display: flex;
		flex-direction: row;
		margin-bottom: 10px;

		.item {
			width: 200px;
			margin-right: 10px;
		}
	}
</style>
