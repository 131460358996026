<template>
	<div>
		<div class="top">
			<span class="title">推手概况：</span>
			<div class="dis-content">
				<!-- @click="handleDetailShow" -->
				<div class="dis-item">
					全部推手
					<div class="dis-num" style="text-align: center;font-size: 14px;margin-top: 10px;">{{statisticsData.AllSuccessCount}}</div>
				</div>
				<div class="dis-item" v-for="(item,index) in statisticsData.MemberDistributorStatisticsRoleList" :key="index">
					{{item.DistributRoleName}}
					<div class="dis-num" style="text-align: center;font-size: 14px;margin-top: 10px;">{{item.SuccessCount }}</div>
				</div>
				<div class="dis-item">
					今日新增
					<div class="dis-num" style="text-align: center;font-size: 14px;margin-top: 10px;">{{statisticsData.TodayAddSuccessCount}}</div>
				</div>
				<div class="dis-item">
					本月新增
					<div class="dis-num" style="text-align: center;font-size: 14px;margin-top: 10px;">{{statisticsData.ThisMonthAddSuccessCount}}</div>
				</div>
			</div>
		</div>
		<div class="main-content">
			<!-- <el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="授权成功" name="0"></el-tab-pane>
				<el-tab-pane label="取消授权" name="1"></el-tab-pane>
			</el-tabs> -->
			<div class="filter-container" style="padding-top: 15px;">
				<!-- 关键字搜索 -->
				<div class="filter-item">
					<label class="label">关键字:</label>
					<el-select class="key-options" v-model="keyOptionsValue" placeholder="推手" @change="keyopChange">
						<!-- <el-option label="全部" :value="null"></el-option> -->
						<el-option v-for="item in keyOptions" :key="item.value"  :label="item.label" :value="item.value"></el-option>
					</el-select>
					<el-input v-model="searchKey" style="margin-left:5px;width: 300px;" :placeholder="distributioHolder" clearable></el-input>
				</div>

				<div class="filter-item" style="margin-left: 20px;">
					<label class="label">授权时间:</label>
					<el-date-picker v-model="authorizedStartTime" type="datetime" placeholder="开始时间" :picker-options="maxOptions"></el-date-picker>
					<span style="margin:0 10px;">~</span>
					<el-date-picker v-model="authorizedEndTime" type="datetime" placeholder="结束时间" :picker-options="minOptions"></el-date-picker>
				</div>
				<div style="margin-top: 10px;">
					<!--  -->
					<div class="filter-item">
						<label class="label">推手级别:</label>
						<el-select v-model="grade">
							<el-option label="全部" :value="null"></el-option>
							<el-option v-for="item in gradeOptions" :key="item.Id" :label="item.DistributRoleName" :value="item.Id"></el-option>
						</el-select>
					</div>
					<!-- 优惠券状态 -->
					<div class="filter-item" style="margin-left: 20px;">
						<label class="label">加入方式:</label>
						<el-select v-model="type">
							<el-option label="全部" :value="null"></el-option>
							<el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</div>
					<!-- button -->
					<div class="filter-item">
						<el-button type="primary" style="margin-left:30px" @click="handleFilter">查询</el-button>
						<button-permissions :datas="'addDistributor'">
							<el-button type="primary" style="margin-left:30px" @click="handleAddDistributor">添加推手</el-button>
						</button-permissions>
						<button-permissions :datas="'importDistributor'">
							<el-button type="primary" style="margin-left:30px" @click="importFile">导入推手</el-button>
						</button-permissions>
					</div>
				</div>
			</div>
			<!-- table -->
			<div class="table-container">
				<el-table :data="tableData" ref="table" style="width: 100%" v-loading="loading" @sort-change="sortChange" :row-key = "(row)=>{return row.Id}"
					@selection-change="selectchange">
					<el-table-column type="selection" width="45"></el-table-column>
					<el-table-column key="1" label="推手" width="250px">
						<template slot-scope="scope">
							<div style="display: flex;align-items: center;">
								<img :src="scope.row.WxHeadUrl ? (scope.row.WxHeadUrl) : defaultHeader" style="width: 50px;height: 50px;border-radius: 100%;">
								<div style="margin-left: 10px;">
									<div class="oneFlows">{{scope.row.Name ? scope.row.Name : (scope.row.WxNickname ? scope.row.WxNickname : '')}}
									</div>
									<div class="flexRow">{{scope.row.Phone}}
										<eyes-hide v-model="scope.row.Phone" :data="{MemberId:scope.row.Id}"></eyes-hide>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column key="5" prop="DistributRoleName" label="推手级别"></el-table-column>
					<el-table-column prop="MemberDistributorAuthTypeValue" label="加入方式" min-width="150" key="MemberDistributorAuthTypeValue"></el-table-column>
					<el-table-column key="1.5" prop="InviteNo" label="邀请码" v-if="RecruitmentType==1||DistributorLockFansType==1">
						<template slot-scope="scope">
							{{scope.row.InviteNo?scope.row.InviteNo:'--'}}
						</template>
					</el-table-column>
					<!-- <el-table-column key="2" prop="Phone" label="手机号" width="115px"></el-table-column> -->
					<el-table-column key="4" label="锁粉店员">
						<template slot-scope="scope">
							<div v-if="scope.row.EmployeeName || scope.row.EmployeePhone">
								<div class="oneFlows">{{scope.row.EmployeeName ? scope.row.EmployeeName : ''}} </div>
								<div>{{scope.row.EmployeePhone ? scope.row.EmployeePhone  : ''}}</div>
							</div>
							<div v-else>--</div>
						</template>
					</el-table-column>
					<el-table-column key="3" label="推荐人">
						<template slot-scope="scope">
							<div v-if="scope.row.RecommendName || scope.row.RecommendPhone">
								<div class="oneFlows">{{scope.row.RecommendName ? scope.row.RecommendName : ''}} </div>
								<div>{{scope.row.RecommendPhone ? scope.row.RecommendPhone : ''}}</div>
							</div>
							<div v-else>--</div>
						</template>
					</el-table-column>
					<el-table-column key="6" label="累计推荐" prop="RecommendSuccessCount" sortable>
						<template slot-scope='scope'>
							<div v-if="scope.row.RecommendSuccessCount>0" style="color: #409EFF;min-width: 30px;min-height: 20px; cursor:pointer"
							 @click="showRecommendList(scope.row)">{{scope.row.RecommendSuccessCount}}</div>
							<div v-else>{{scope.row.RecommendSuccessCount}}</div>
						</template>
					</el-table-column>
					<el-table-column key="12" label="锁粉客户" sortable prop="FansSuccessCount">
						<template slot-scope='scope'>
							<div v-if="scope.row.FansSuccessCount>0" style="color: #409EFF;min-width: 30px;min-height: 20px; cursor:pointer" @click="showlockfanList(scope.row)">{{scope.row.FansSuccessCount}}</div>
							<div v-else>{{scope.row.FansSuccessCount}}</div>
						</template>
					</el-table-column>
					<el-table-column key="7" sortable prop="CommissionBalance" label="累计收益"></el-table-column>
					<!-- <el-table-column key="8" sortable prop="Sell" label="累计业绩"></el-table-column> -->
					<el-table-column key="9" sortable prop="MemberDistributorAuthTime" label="授权时间" width="180px"></el-table-column>
					<el-table-column key="11" label="操作" width="200px">
						<template slot-scope="scope">
							<button-permissions :datas="'changeDistributorLevel'">
								<el-button type="text " @click="handleEditGrade(scope.row)">修改等级</el-button>
							</button-permissions>

							<el-dropdown trigger="click" style="margin-left: 15px;color: #409EFF;">
								<span class="el-dropdown-link" style="cursor: pointer;" v-if="isMoreShow">更多</span>
								<el-dropdown-menu slot="dropdown" v-if="isMoreShow">

									<el-dropdown-item>
										<button-permissions :datas="'changeLink'">
											<div @click="changeRecommend(scope.row)">修改推荐人</div>
										</button-permissions>
									</el-dropdown-item>

									<el-dropdown-item>
										<button-permissions :datas="'FlowdatanalysisBtn'">
											<div @click="Flowdatanalysis(scope.row)">流量数据分析</div>
										</button-permissions>
									</el-dropdown-item>
									<el-dropdown-item>
										<button-permissions :datas="'cancelDistributor'">
											<div @click="handleCancAuthorize(scope.row)">取消授权</div>
										</button-permissions>
									</el-dropdown-item>

								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;padding-bottom: 20px;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
						<el-checkbox :value="allcheck" @change="checkboxChaneg">全选</el-checkbox>
						<buttonPermissions :datas="'changeDistributorLevel'" style="margin-left:20px">
							<el-button size="mini" @click="BatchChanegLevel">批量修改等级</el-button>
						</buttonPermissions>
					</div>
					<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
					 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size"
					 layout="total, sizes, prev, pager, next, jumper" :total="page.total"></el-pagination>
				</div>
			</div>

			<!-- 推手概况详情 -->
			<el-dialog :title="`${currentRow.Name||currentRow.WxNickname||currentRow.Phone}的推荐`" :visible.sync="recommendDialogVisible"
			 v-if="recommendDialogVisible" width="800px">
				<recommend-detail :memberId="currentRow.Id"></recommend-detail>
			</el-dialog>

			<!-- 修改直接推荐人 -->
			<el-dialog title="修改直接推荐人" :visible.sync="editDirectRecommend" width="600px">
				<div class="direct-recommend-content">
					<div style="margin-top: 10px;">修改直接推荐人：</div>
					<div>
						<el-input placeholder="输入推荐人手机号,点击右侧查询" v-model="checkRecommend.recommendPhone"></el-input>
						<el-button type="primary" style="margin-left:20px;" @click="recommendSearch">查询</el-button>
						<div class="referrer-card" v-if="checkRecommend.isShowRecommend">
							<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
								<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
							</div>
							<img class="referrer-img" :src="recommendData.WxHeadUrl ? recommendData.WxHeadUrl : defaultHeader" />
							<div class="referrer-text">
								<div class="name">{{recommendData.Name?recommendData.Name:recommendData.WxNickname}}</div>
								<div class="name">{{recommendData.DistributRoleName}}</div>
							</div>
						</div>
						<div style="color:#E51C23;font-size:12px;margin-top: 10px;" v-if="checkRecommend.isShowRecTip">查询不到相关推荐人数据，请确认输入手机号无误</div>
					</div>
				</div>

				<div style="text-align:center;">
					<el-button style="margin:30px 30px 0 30px;width:150px;" @click="editDirectRecommend=false">关闭</el-button>
					<el-button style="width:150px;" type="primary" @click="saveEditDirectRecommend">确认修改</el-button>
				</div>
			</el-dialog>

			<!-- 添加直接推荐人 -->
			<el-dialog title="添加推手" :visible.sync="editDialogVisible" width="800px">
				<edit-distributor v-if="editDialogVisible" @closeDialog="closeDialog" @refreshData="refreshData"></edit-distributor>
			</el-dialog>

			<!-- 修改等级 -->
			<el-dialog class="edit-grade" :title="changeTitle" :visible.sync="editGradeDialog" width="600px" @closed="closededitGrade">
				<span>修改推手等级：</span>
				<el-select v-model="editGrade" placeholder="请选择">
					<el-option v-for="item in gradeOptions" :key="item.Id" :label="item.DistributRoleName" :value="item.Id"></el-option>
				</el-select>
				<div style="text-align:center;">
					<el-button style="margin:30px 30px 0 30px;width:150px;" @click="editGradeDialog=false">关闭</el-button>
					<el-button style="width:150px;" type="primary" :loading="changeButtonLoading" @click="confirmChangeLevel">确认修改</el-button>
				</div>
			</el-dialog>

			<!-- 取消授权 -->
			<el-dialog :visible.sync="cancelAuthorize" width="400px" :show-close="false">
				<div class="authorize-content">
					<div class="title">
						<span>{{currentRow.Name?currentRow.Name:currentRow.WxNickname}}</span><span v-if="currentRow.Phone">（{{currentRow.Phone}}）</span>账户上目前还存在：</div>
					<div class="message">
						<div>(1)<span style="margin-left:5px">待结算收益{{cancelvalitedata.UnfinishBalance}}元</span></div>
					</div>
					<div class="message">
						<div>(2)<span style="margin-left:5px">提现中收益{{cancelvalitedata.DrawingBalance}}元</span></div>
					</div>
					<div class="message">
						<div>(3)<span style="margin-left:5px">已结算，可提现收益{{cancelvalitedata.WaitDrawBalance}}元</span></div>
					</div>
					<div class="message">
						<div>(4)<span style="margin-left:5px">直接推荐推手{{cancelvalitedata.DirectRecommendSuccessCount}}人</span></div>
					</div>
					<div class="remark" style="margin-top: 20px;line-height: 22px;">
						若直接取消授权，提现中收益可正常审核，待结算收益不再结算，可提现收益可继续提现；由
						<span>{{currentRow.WxNickname?currentRow.WxNickname:currentRow.Name}}</span>
						推荐的推手，直接推荐人信息将清空。 是否确认继续取消
						<span>{{currentRow.WxNickname?currentRow.WxNickname:currentRow.Name}}</span>
						授权？
					</div>
				</div>
				<div style="text-align:center;">
					<el-button style="margin:30px 30px 0 0;width:120px;" @click="cancelAuthorize=false">关闭</el-button>
					<el-button style="width:120px;" type="primary" @click="saveAuthorize">确认取消授权</el-button>
				</div>
			</el-dialog>

			<!-- 锁粉客户弹框 -->
			<el-dialog :visible.sync="lockCustomerVisible" width="1000px" :title="lockcustomerName">
				<div class="filter-container">
					<div class="filter-item">共计：{{lockInfor.AllSuccessCount}}人</div>
					<div class="filter-item">待转化：{{lockInfor.WaitConvertSuccessCount}}人</div>
					<div class="filter-item">已转化：{{lockInfor.HaveConvertSuccessCount}}人</div>
					<div class="filter-item">已失效：{{lockInfor.HaveFailCount}}人</div>
				</div>
				<div class="filter-container">
					<div class="filter-item">
						<label class="label">关键字: </label>
						<el-input v-model="searchKeyfan" placeholder="手机号/微信昵称/姓名" style="width:250px;margin-right: 10px;" clearable
						 @keyup.enter.native="lockFilter"></el-input>
					</div>

					<div class="filter-item">
						<label class="label">锁粉状态: </label>
						<el-select v-model="lockfankType" style="width: 160px;margin-right: 10px;" clearable>
							<el-option label="全部" :value="null"></el-option>
							<el-option v-for="item in lockfankTypeList" :key="item.id" :label="item.value" :value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="filter-item">
						<el-button type="primary" @click="lockFilter">查询</el-button>
					</div>
				</div>

				<div class="table-container">
					<el-table :data="lockfanData">
						<el-table-column prop="BrandName" label="客户" v-loading="lockLoading">
							<template slot-scope="scope">
								<div style="display: flex;flex-direction: row;align-items: center;">
									<img style="width:50px;height:50px;border-radius:100%;object-fit:cover;border:1px solid #ddd;" v-if="scope.row.WxHeadUrl==''"
									 src='https://cdn.dkycn.cn/images/melyshop/employeeManageDefaultAvatar.png' />
									<!-- <img style="width:50px;height:50px;border-radius:100%;object-fit:contain;" v-if="scope.row.WxHeadUrl==''" src='https://cdn.dkycn.cn/images/melyshop/pcDefaultHeader.png'/> -->
									<img style="width:50px;height:50px;border-radius:100%;object-fit:cover;border:1px solid #ddd;" v-else :src='scope.row.WxHeadUrl' />
									<span style="margin-left:5px;" class="over-two" v-if="scope.row.Name||scope.row.WxNickname">{{scope.row.Name||scope.row.WxNickname}}</span>
									<span v-else>匿名用户</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="手机号" prop="Phone">
							<template slot-scope="scope">
								<div v-if="scope.row.Phone">{{scope.row.Phone}}</div>
								<div v-else>--</div>
							</template>
						</el-table-column>
						<el-table-column prop="FansTypeValue" label="锁粉状态"></el-table-column>
						<el-table-column prop="FirstFromTime" label="锁粉时间"></el-table-column>
						<el-table-column label="到期时间">
							<template slot-scope="scope">
									{{scope.row.FirstFromExpireTimeValue}}
							</template>
						</el-table-column>
					</el-table>
					<div style="margin:20px 0;text-align:right;">
						<el-pagination v-if="lockTotal" @size-change="lockhandleSizeChange" @current-change="lockhandleCurrentChange"
						 :current-page="lockcurrentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="lockpageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="lockTotal">
						</el-pagination>
					</div>
				</div>
			</el-dialog>

			<!-- 导入推手 -->
			<el-dialog :visible.sync="importExtendShow" title="导入推手" width="650px" v-loading="importExtendLoading" @closed="closeImportShow"
				custom-class="dialog-body-paddingTop-10">
				<div class="yellowBox">
					请下载推手导入模板，根据文件中的填写须知，按要求完善数据再导入
					<el-button @click="getFileOriginal" class="margin-left-20">下载模板</el-button>
				</div>
				<div v-if="extendFile.IsExistProgress" class="file-box">
					<div class="flex flex-align-center flex-column" v-if="!extendFile.IsComplete">
						<div class="flex flex-align-center">
							<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/exce-distributionInquiry20240513.png" style="width:30px" alt="">
							<div class="margin-left-20 margin-right-20 loading">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
							<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/image-distributionInquiry20240513.png" style="width:30px" alt="">
						</div>
						<div style="text-align:center">
							<div class="color-606266 margin-top-20">数据导入中...</div>
							<div class="color-999999 margin-top-10">导入需要一定时间，请勿关闭操作框</div>
						</div>
					</div>
					<div style="text-align:center" v-else>
						<div class="color-606266">导入完成</div>
						<div class="color-999999 margin-top-10">成功导入{{extendFile.SuccessCount}}条，失败{{extendFile.FailCount}}条，可下载导入失败数据</div>
						<el-button class="margin-top-20" v-if="extendFile.FailCount>0" @click="downFile">下载导入失败数据</el-button>
					</div>
				</div>
				<el-upload :action="uploadFile" accept=".xls,.xlsx" class="file-box" :show-file-list="false" :before-upload="beforeUpload"
					 :on-success="handleUploadSuccess" v-else>
					<div v-if="extendFile.filePath" class="color-999999">
						<i class="el-icon-document"></i>
						<div class="margin-top-10 margin-bottom-20">{{extendFile.fileName}}</div>
					</div>
					<div v-else class="color-999999">
						<div>
							点击按钮上传文件，
						</div>
						<div class="margin-top-10 margin-bottom-10">
							支持xls、xlsx格式
						</div>
					</div>
					<el-button>上传文件</el-button>
				</el-upload>
				<div style="text-align:center" class="margin-top-30">
					<el-button type="primary" style="width:300px" v-if="hasConfirmBtn" @click="confirmImport">确认导入</el-button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	import editDistributor from './editDistributorDia.vue';
	import recommendDetail from './recommendDetailDia.vue';
	import config from '@/config/index';
	import eyesHide from "@/components/eyesHide"
	import {
		distfilterBoxList,
		distributorsearch,
		changememberdistributorrole,
		memberdistributorstatistics,
		recommendmemberinfobyphone,
		changememberdistributorrecommend,
		memberdistibutorcancelvalitedata,
		memberdistibutorcancel,
		membermemberdistributorisexistprogress,
		membermemberdistributorimport,
		membermemberdistributorimportiscomplete
	} from '@/api/wyUsedInterface.js'
	import {
		memberLockFansTypeSuccessCount,
		memberLockFansPageInit
	} from '@/api/goods.js'

	import {
		memRecommendInfobyId
	} from '@/api/TurnTomySelf.js'
	import buttonPermissions from '@/components/buttonPermissions';
	import {
		mapGetters
	} from 'vuex'
import { flashSale } from '../../mallPage/components/browseModule';
	export default {
		components: {
			editDistributor,
			recommendDetail,
			buttonPermissions,
			eyesHide
		},
		data() {
			return {
				IshowPushtradeanalysis:false,
				lockTotal: 0,
				lockcurrentPage: 1,
				lockpageSize: 10,
				lockfanData: [],
				lockLoading: false,
				lockfankType: null,
				searchKeyfan: '',
				lockfankTypeList: [{
						id: 1,
						value: '待转化'
					},
					{
						id: 2,
						value: '已转化'
					},
					{
						id: 3,
						value: '已失效'
					}
				],
				lockcustomerName: '野狗的锁粉客户',
				lockCustomerVisible: false,
				isMoreShow: true,
				distributioHolder: '姓名、微信昵称、手机号',
				activeName: '0',
				keyOptionsValue: 0,
				keyOptions: [{
						label: '推手',
						value: 0
					},
					{
						label: '锁粉店员',
						value: 1
					},
					{
						label: '直接推荐人',
						value: 2
					}
				],
				searchKey: '',
				grade: null,
				gradeOptions: [],
				type: null,
				typeOptions: [{
						label: '购买授权礼包商品',
						value: 1
					},
					{
						label: '累计完成交易',
						value: 5
					},
					{
						label: '无门槛授权',
						value: 4
					},
					{
						label: '余额单笔充值',
						value: 6
					},
					{
						label: '手动添加',
						value: 2
					},
				],
				authorizedStartTime: '',
				authorizedEndTime: '',
				tableData: [],
				page: {
					total: 0,
					size: 20,
					current: 1
				},
				recommendDialogVisible: false,
				editDirectRecommend: false,
				directRecommendName: '',
				cancelAuthorize: false,
				editDialogVisible: false,
				editGradeDialog: false,
				editGrade: '',
				authorizeList: ['hhhhhhh', 'jjjjjjj'],
				currentRow: {},
				statisticsData: {},
				recommendData: {},
				checkRecommend: {
					lockchecked: false,
					recommendPhone: '',
					isShowRecommend: false,
					isShowRecTip: false
				},
				cancelvalitedata: {},
				loading: false,
				imgUrl: config.IMG_BASE,
				defaultHeader: config.DEFAULT_HEADER,
				OrderByTpye: '',
				IsAsc: 0,
				lockInfor: {},
				lookLockfandata: {},


				checkList:[],
				changeButtonLoading:false,
				changeStyle:1,
				changeTitle:'修改推手等级',
				importExtendShow:false,
				uploadFile:config.UPLOAD_ZIP,
				extendFile:{
					filePath:'',
					fileName:'',
					importId:0,
					SuccessCount:0,
					FailCount:0,
					IsComplete:false,
					IsExistProgress:false,
				},
				hasConfirmBtn:false,
				importExtendLoading:false,
				timeOut:null
			};
		},
		computed: {
			...mapGetters([
				'routerList',
				"mallInfo",
				'RecruitmentType',
				'DistributorLockFansType'
			]),
			allcheck(){
				// console.log(this.tableData)
				if(this.tableData.length){
					return this.tableData.every(v=>{
						return this.checkList.some(x=>{
							return v.Id==x.Id
						})
					})
				}else{
					return false
				}
			},
			//日期选择器限制选择
			minOptions: function() {
				let limitTime = this.authorizedStartTime;
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(limitTime);
						}
					}
				};
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.authorizedEndTime;
				return {
					disabledDate(time) {
						if (limitTime) {
							return time > new Date(limitTime);
						}
					}
				};
			}
		},
		created () {
				if(this.$route.params.Id){
					this.grade = this.$route.params.Id
				}else if(this.$route.query.msg){
					// console.log(this.$route.query.msg)
					this.searchKey = this.$route.query.msg
				}
				// console.log(this.$route.params)
		},
		beforeMount() {
			// console.log(this.mallInfo,'111')
			if(!this.mallInfo.IsUseQyWeixin){
					this.keyOptions = this.keyOptions.filter((v)=>{
						return v.label != '锁粉店员'
					})
			}
			
			this.getStatisticsData()
			this.getAuthList()
			this.getDataList()
			this.getRouterList()
		},
		methods: {
			closeImportShow(){
				this.extendFile = {
					filePath:'',
					fileName:'',
					importId:0,
					SuccessCount:0,
					FailCount:0,
					IsComplete:false,
					IsExistProgress:false,
				}
				this.hasConfirmBtn = false
				clearTimeout(this.timeOut)
			},
			downFile(){
				window.open(location.origin+'/pc/member/memberdistributorimporterrordownload?Id='+this.extendFile.importId)
			},
			async confirmImport(){
				try{
					this.importExtendLoading = true
					let res = await membermemberdistributorimport({
						ExcelFilePath:this.extendFile.filePath,
						ExcelFileName:this.extendFile.fileName,
					})
					if(res.IsSuccess){
						this.extendFile.importId = res.Result.Id
						this.extendFile.IsExistProgress = true
						this.hasConfirmBtn = false
						this.getResult()
					}
				}finally{
					this.importExtendLoading = false
				}
			},
			async getResult(){
				try{
					let res = await membermemberdistributorimportiscomplete({
						Id:this.extendFile.importId
					})
					if(res.IsSuccess){
						if(res.Result.IsComplete){
							for(let key in res.Result){
								this.extendFile[key] = res.Result[key]
							}
						}else{
							this.timeOut = setTimeout(()=>{
								this.getResult()
							},1000)
						}
					}
				}finally{

				}
			},
			handleUploadSuccess(file){
				this.extendFile.filePath = file[0]
				this.hasConfirmBtn = true
			},
			beforeUpload(file){
				var type = file.name.split('.')[1];
				if (['xls', 'xlsx'].indexOf(type) > -1){
					this.extendFile.fileName = file.name;
					return true;
				}else{
					this.$message.error('请选择正确的文件上传')
					return false;
				}
			},
			getFileOriginal(){
				let url = `https://cdn.dkycn.cn/${encodeURIComponent('推手导入模板文件2024')}.xlsx`
				window.open(url)
			},
			importFile(){
				this.importExtendShow = true
				this.getSchedule()
			},
			async getSchedule(){
				try{
					this.importExtendLoading = true
					let res = await membermemberdistributorisexistprogress()
					if(res.IsSuccess){
						// console.log(res.Result)
						this.extendFile.IsExistProgress = res.Result.IsExistProgress
						this.extendFile.importId = res.Result.Id
						if(this.extendFile.IsExistProgress){
							this.getResult()
						}
					}
				}finally{
					this.importExtendLoading = false
				}
			},
			closededitGrade(){
				this.editGrade = null
				this.editGradeDialog = false
			},
			BatchChanegLevel(){
				if(this.checkList.length){
					this.changeStyle = 2
					this.changeTitle = '批量修改推手等级'
					this.editGradeDialog = true
				}else{
					this.$message.error('请选择推手')
				}
			},
			checkboxChaneg(e){
				this.$refs.table.toggleAllSelection()
			},
			selectchange(e){
				// console.log(e)
				this.checkList = e
			},
			//流量数据分析
			Flowdatanalysis(record) {
				if(this.IshowPushtradeanalysis){
					this.$router.push({
						path: '/distribution/Pushtradeanalysis',
						query: {
							id: record.Id,
						}
					})
				}
				else{
					this.$message({
						showClose: true,
						type: 'error',
						message: '暂无该页面权限!'
					});
				}
				
			},
			// 锁粉客户
			showlockfanList(row) {
				this.searchKeyfan = ''
				this.lockfankType = ''
				this.lookLockfandata = row
				this.getLockNumber()
				this.lockFanlist()
				this.lockcustomerName = (row.Name || row.WxNickname || row.Phone) + '的锁粉客户'
				this.lockCustomerVisible = true
			},
			// 获取锁粉客户各类型数量
			async getLockNumber() {
				try {
					// 获取锁粉客户各类型数量
					let result = await memberLockFansTypeSuccessCount({
						MemberId: this.lookLockfandata.Id
					})
					this.lockInfor = result.Result
				} catch (e) {
					//TODO handle the exception
				} finally {

				}

			},
			// 锁粉客户查询
			lockFilter() {
				this.lockcurrentPage = 1
				this.lockFanlist()
			},
			// 锁粉客户列表
			async lockFanlist() {
				try {
					this.lockLoading = true
					let data = {
						MemberId: this.lookLockfandata.Id,
						KeyWords: this.searchKeyfan,
						FansType: this.lockfankType,
						Skip: (this.lockcurrentPage - 1) * this.lockpageSize,
						Take: this.lockpageSize,

					}
					let result = await memberLockFansPageInit(data)
					this.lockfanData = result.Result.Results
					this.lockTotal = result.Result.Total

				} catch (e) {
					//TODO handle the exception
				} finally {
					this.lockLoading = false
				}

			},
			lockhandleSizeChange(val) {
				this.lockpageSize = val;
				this.lockFanlist();
			},
			lockhandleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.lockcurrentPage = val;
				this.lockFanlist();
			},
			getRouterList() {
				if (this.routerList.some(item => {
						return item == 'changeLink' || item == 'cancelDistributor'
					})) {
					this.isMoreShow = true
				} else {
					this.isMoreShow = false
				}
				//有推手数据分析这个页面的权限
				if (this.routerList.some(item => {
						return item == 'Pushtradeanalysis'
					})) {
					this.IshowPushtradeanalysis = true
				}
				else{
					this.IshowPushtradeanalysis = false
				}
			},
			// 关键字类型 改变
			keyopChange() {
				if (this.keyOptionsValue == 0 || this.keyOptionsValue == 2) {
					this.distributioHolder = '姓名、微信昵称、手机号'
				}
				if (this.keyOptionsValue == 1) {
					this.distributioHolder = '姓名、手机号'
				}
			},
			sortChange(column, prop, order) {
				if (column.prop == "CommissionBalance") {
					this.OrderByTpye = 1
				} else if (column.prop == "Sell") {
					this.OrderByTpye = 2
				} else if (column.prop == "MemberDistributorAuthTime") {
					this.OrderByTpye = 0
				} else if (column.prop == "RecommendSuccessCount") {
					this.OrderByTpye = 4
				} else if (column.prop == "FansSuccessCount") {
					this.OrderByTpye = 3
				}


				if (column.order == "ascending") {
					// 正序
					this.IsAsc = true
				} else {
					this.IsAsc = false
				}
				this.currentPage = 1;
				this.getDataList();
			},
			async getDataList() {
				try {
					this.loading = true
					let data = {
						KeyWordsType: this.keyOptionsValue,
						KeyWords: this.searchKey,
						StartTime: this.authorizedStartTime,
						EndTime: this.authorizedEndTime,
						DistributorRoleId: this.grade,
						DistributorAuthType: this.type,
						Skip: (this.page.current - 1) * this.page.size,
						Take: this.page.size,
						OrderByTpye: this.OrderByTpye,
						IsAsc: this.IsAsc

					}
					let result = await distributorsearch(data)
					this.tableData = result.Result.Results
					this.page.total = result.Result.Total
				} catch (e) {
					//TODO handle the exception
				} finally {
					this.loading = false
				}
			},
			async getAuthList() {
				try {

					let result = await distfilterBoxList()
					this.gradeOptions = result.Result

				} catch (e) {
					//TODO handle the exception
				}
			},
			async getcancelvalitedata() {
				try {
					let data = {
						MemberId: this.currentRow.Id
					}
					let result = await memberdistibutorcancelvalitedata(data)
					this.cancelvalitedata = result.Result
					this.cancelAuthorize = true

				} catch (e) {
					//TODO handle the exception
				}
			},
			async getStatisticsData() {
				try {

					let result = await memberdistributorstatistics()
					this.statisticsData = result.Result

				} catch (e) {
					//TODO handle the exception
				}
			},
			handleClick(val) {
				this.activeName = val.index == 0 ? '0' : '1';
			},
			handleFilter() {
				this.page.current = 1
				this.getDataList()
			},
			handleAddDistributor() {
				this.editDialogVisible = true
			},
			closeDialog() {
				this.editDialogVisible = false
			},
			handleSizeChange(val) {
				this.page.size = val;
				this.getDataList();
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page.current = val;
				this.getDataList();
			},
			handleDetailShow() {
				this.recommendDialogVisible = true;
			},
			//修改等级
			handleEditGrade(row) {
				this.currentRow = row;
				this.editGrade = row.MemberDistributorRoleId
				this.changeStyle = 1
				this.changeTitle = '修改推手等级'
				this.editGradeDialog = true;
			},
			//取消二级授权
			handleCancAuthorize(row) {
				this.currentRow = row;
				this.getcancelvalitedata();
			},
			//关闭取消授权
			canselAuthorize() {
				this.cancelAuthorize = false;
			},
			//确认取消授权
			async saveAuthorize() {
				this.cancelAuthorize = false;
				try {
					let data = {
						MemberId: this.currentRow.Id
					}
					let result = await memberdistibutorcancel(data)
					if (result.IsSuccess) {
						this.getDataList()
						this.getStatisticsData()
						this.$message({
							showClose: true,
							type: 'success',
							message: '取消授权成功'
						});
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			confirmChangeLevel(){
				if(this.changeStyle==1){
					this.saveEditGrade()
				}else{
					if(!this.editGrade){
						this.$message.error('请选择推手等级')
						return
					}
					const h = this.$createElement
					this.$msgbox({
						title: '提示',
						message: h('p', null, [
							h('span', null, `是否确认将已选中的${this.checkList.length}名推手级别修改为`),
							h('span', { style: 'color: #f56c6c' }, this.gradeOptions.find(v=>{return this.editGrade==v.Id}).DistributRoleName),
							h('span', null, '？修改成功后不可撤消。'),
						]),
						showCancelButton: true,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(()=>{
						this.funBatchChange()
					}).catch(()=>{})
				}
			},
			async funBatchChange(){
				this.changeButtonLoading = true
				
				try{
					for(let item of this.checkList){
						// console.log(item)
						let data = {
							MemberId: item.Id, //推手,
							DistributorRoleId: this.editGrade, //新分销等级Id
							noError:true
						}
						let result = await changememberdistributorrole(data)
					}
				}finally{
					this.changeButtonLoading = false
					this.editGradeDialog = false
					this.getDataList()
					this.getStatisticsData()
					this.$message({
						showClose: true,
						type: 'success',
						message: '推手等级修改成功'
					});
				}
			},
			//确认修改等级
			async saveEditGrade() {
				this.changeButtonLoading = true;
				try {
					let data = {
						MemberId: this.currentRow.Id, //推手,
						DistributorRoleId: this.editGrade, //新分销等级Id
					}
					let result = await changememberdistributorrole(data)
					if (result.IsSuccess) {
						this.getDataList()
						this.getStatisticsData()
						this.$message({
							showClose: true,
							type: 'success',
							message: '推手等级修改成功'
						});
					}
				} catch (e) {
					//TODO handle the exception
				}finally{
					this.changeButtonLoading = false
					this.editGradeDialog = false
				}
			},
			//查看更多
			handleShowMore() {},
			canselEditDirectRecommend() {},
			async saveEditDirectRecommend() {
				if (this.recommendData && this.recommendData.Id > 0) {
					try {
						let data = {
							MemberId: this.currentRow.Id,
							MemberDistributorRecommendId: this.recommendData.Id
						}
						let result = await changememberdistributorrecommend(data)
						if (result.IsSuccess) {
							this.editDirectRecommend = false
							this.getDataList()
							this.getStatisticsData()
							this.$message({
								showClose: true,
								type: 'success',
								message: '直接推荐人修改成功'
							});
						}
					} catch (e) {
						//TODO handle the exception
					}
				} else {
					this.$message({
						showClose: true,
						type: 'warning',
						message: '请选择直接推荐人'
					});
				}
			},
			refreshData() {
				this.editDialogVisible = false;
				this.getDataList()
			},
			showRecommendList(row) {
				this.currentRow = row;
				this.recommendDialogVisible = true
			},
			changeRecommend(row) {
				this.checkRecommend = {
					lockchecked: false,
					recommendPhone: row.RecommendPhone,
					isShowRecTip: false
				};
				this.currentRow = row;
				this.editDirectRecommend = true
				//初始化直接推荐人信息
				this.getRecommendInfobyId()
				// if(row.RecommendPhone){
				// 	this.getRecommendInfobyId()
				// }
				// else{
				// 	this.checkRecommend.isShowRecommend = false
				// }


			},
			//获取推荐人信息
			async getRecommendInfobyId() {
				this.loading = true
				try {
					let data = {
						MemberId: this.currentRow.Id
					}
					let result = await memRecommendInfobyId(data)
					if (result.IsSuccess) {
						if (result.Result.MemberDistributorRoleId == 0) {
							this.checkRecommend.isShowRecommend = false
						} else {
							this.recommendData = result.Result
							this.checkRecommend.isShowRecommend = true
						}

					}
				} catch (err) {

				} finally {
					this.loading = false
				}

			},
			recommendSearch() {
				if (this.checkRecommend.recommendPhone.length == 11) {
					this.getRecommendData()
				} else {
					if (this.checkRecommend.recommendPhone.length == 0) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入推荐人手机号'
						});
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入正确的手机号'
						});
					}
				}
			},
			async getRecommendData() {
				try {
					let data = {
						Phone: this.checkRecommend.recommendPhone
					}
					let result = await recommendmemberinfobyphone(data)
					if (result.IsSuccess) {

						if (result.Result.Id > 0) {
							this.checkRecommend.isShowRecommend = true
							this.checkRecommend.isShowRecTip = false
							this.recommendData = result.Result
							// console.log(this.recommendData)
						} else {
							this.checkRecommend.isShowRecommend = false
							this.checkRecommend.isShowRecTip = true
						}

						this.$forceUpdate()
					}
				} catch (e) {
					//TODO handle the exception

				}
			},
		}
	};
</script>
<style lang="less" scoped>
.file-box{
	border: 1px solid #DCDFE6;
	width: 500px;
	height: 172px;
	margin: 20px auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.yellowBox{
	background: #FDF6EC;
	padding: 15px ;
	font-size: 14px;
	color: #FDA23C;
}
.flexRow{
	display: flex;
	align-items: center;
}
	.top {
		background: #fff;
		padding: 20px;

		.title {
			font-size: 14px;
		}

		.dis-item {
			// cursor: pointer;
			margin-top: 20px;
			margin-left: 10px;
			font-size: 14px;
			margin-right: 50px;
		}

		.dis-content {
			display: flex;
			flex-direction: row;
		}

		.dis-num {
			color: #999;
			font-size: 12px;
			line-height: 20px;
		}
	}

	.main-content {
		margin-top: 15px;
		background: #fff;
		padding: 0 10px;
	}

	.key-options {
		::v-deep .el-input__inner {
			width: 130px !important;
		}
	}

	.edit-grade {
		::v-deep .el-input {
			width: 350px !important;
		}
	}

	.authorize-content {
		line-height: 20px;

		.message {
			margin-left: 5px;
		}
	}

	.direct-recommend-content {
		display: flex;
		flex-direction: row;

		::v-deep .el-input {
			width: 260px;
		}
	}

	.referrer-card {
		width: 260px;
		height: 60px;
		border: 1px solid #67c23a;
		margin-top: 10px;
		border-radius: 1px;
		position: relative;
		padding: 5px;
		display: flex;
		flex-direction: row;
		line-height: 20px;
		align-items: center;

		.check {
			position: absolute;
			right: -1px;
			top: -4px;
		}

		::v-deep .el-checkbox {
			width: 14px;
			height: 14px;
		}

		::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
		.el-checkbox__input.is-indeterminate .el-checkbox__inner {
			background-color: #67c23a;
			border-color: #67c23a;
		}

		::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
			border-color: #67c23a;
		}

		::v-deep .el-checkbox__inner:hover {
			border-color: #67c23a;
		}

		.referrer-img {
			width: 50px;
			height: 50px;
			border-radius: 100%;
			object-fit: cover;
			margin: 0 10px 0 10px;
		}

		.referrer-text {
			display: inline-block;
			width: 165px;
			overflow: hidden;
		}

		.name {
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-size: 14px;
			color: #909399;
		}
	}

	.oneFlows {
		width: 100px;
		margin-right: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

	}

	.over-two {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
</style>
