<template>
	<div class="content">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
			<el-form-item label="姓名：" prop="name"><el-input v-model="ruleForm.name" placeholder="最多输入20个字" maxlength="20"></el-input></el-form-item>
			<el-form-item label="手机号：" prop="phone"><el-input v-model="ruleForm.phone"></el-input></el-form-item>
			<el-form-item label="推手级别：" prop="grade">
				<el-select v-model="ruleForm.grade" placeholder="请选择">
					<el-option v-for="item in gradeOptions" :key="item.Id" :label="item.DistributRoleName" :value="item.Id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="推荐人：">
				<el-input placeholder="输入推荐人手机号，点击右侧查询" v-model="ruleForm.recommendPhone"></el-input>
				<el-button type="primary" style="margin-left:20px;" @click="recommendSearch">查询</el-button>
				<div class="referrer-card" v-if="isShowRecommend">
					<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
						<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
					</div>
					<img class="referrer-img" :src="recommendData.WxHeadUrl ? recommendData.WxHeadUrl : defaultHeader" />
					<div class="referrer-text">
						<div class="name">{{ recommendData.Name }}</div>
						<div class="name">{{ recommendData.DistributRoleName }}</div>
					</div>
				</div>
				<div style="color:#E51C23;font-size:12px;" v-if="isShowRecTip">查询不到相关推荐人数据，请确认输入手机号无误</div>
			</el-form-item>
			<el-form-item label="锁粉店员：">
				<el-input placeholder="输入锁粉店员手机号" v-model="ruleForm.lockPhone"></el-input>
				<el-button type="primary" style="margin-left:20px;" @click="lockSearch">查询</el-button>
				<div class="referrer-card" v-if="isShowLock">
					<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
						<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
					</div>
					<img class="referrer-img" :src="lockData.WxHeadUrl ? lockData.WxHeadUrl : defaultHeader" />
					<div class="referrer-text">
						<div class="name">{{ lockData.EmployeeName }}</div>
						<div class="name">{{ lockData.ShopName }}</div>
					</div>
				</div>
				<div style="color:#E51C23;font-size:12px;" v-if="ruleForm.lockPhone.length == 11 && isShowLockTip">查询不到相关店员数据，请确认输入手机号无误</div>
			</el-form-item>

			<!-- <el-form-item label="微信标识："><el-input placeholder="请在客户详情页中复制该推手的微信标识，并粘贴在此处" v-model="ruleForm.wxUnion"></el-input></el-form-item> -->
		</el-form>
		<div style="text-align:center;">
			<el-button style="margin:20px 30px;width:150px;" @click="suppilerCansel">关闭</el-button>
			<el-button style="width:150px;" type="primary" @click="saveSupiler('ruleForm')">确认添加</el-button>
		</div>
	</div>
</template>
<script>
import config from '@/config/index';
import { distfilterBoxList, memberdistributoradd, recommendmemberinfobyphone, employeeinfobyphone } from '@/api/wyUsedInterface.js';
import {
		mapGetters
	} from 'vuex'
export default {
	computed:{
		...mapGetters([
				"mallInfo"
			]),
	},
	data() {
		name: 'editDisributor';
		return {
			ruleForm: {
				name: '',
				phone: '',
				grade: '',
				wxUnion: '',
				lockchecked: false,
				recchecked: false,
				lockPhone: '',
				recommendPhone: ''
			},
			gradeOptions: [],
			rules: {
				name: [
					{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}
				],
				phone: [
					{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}
				],
				grade: [
					{
						required: true,
						message: '请选择推手级别',
						trigger: 'blur'
					}
				]
			},
			isShowRecommend: false,
			recommendData: {},
			isShowLock: false,
			lockData: {},
			isShowRecTip: false,
			isShowLockTip: false,
			imgUrl: config.IMG_BASE,
			defaultHeader:config.DEFAULT_HEADER
		};
	},
	beforeMount() {
		this.getAuthList();
	},
	methods: {
		async addCustomer() {
			try {
				let data = {
					Name: this.ruleForm.name,
					Phone: this.ruleForm.phone,
					DistributorRoleId: this.ruleForm.grade, //推手级别,
					UnionId: this.ruleForm.wxUnion,
					DistributorRecommendId:this.recommendData.Id , //推手推荐人Id,
					LockMallEmployeeId: this.lockData.Id //锁粉店员Id,
				};
				
				let res = await memberdistributoradd(data);
				if (res.IsSuccess) {
					this.$message({showClose: true,
						type: 'success',
						message: '推手添加成功'
					});
					this.$refs['ruleForm'].resetFields();
					(this.isShowRecommend = false),
						(this.recommendData = {}),
						(this.isShowLock = false),
						(this.lockData = {}),
						(this.isShowRecTip = false),
						(this.isShowLockTip = false);
					this.$emit('refreshData');
				}
			} catch (e) {
				//TODO handle the exception
			}
		},
		async getAuthList() {
			try {
				let result = await distfilterBoxList();
				this.gradeOptions = result.Result;
			} catch (e) {
				//TODO handle the exception
			}
		},
		suppilerCansel() {
			this.$emit('closeDialog');
		},
		saveSupiler(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.addCustomer();
				} else {
					// console.log('error submit!!');
					return false;
				}
			});
		},
		recommendSearch() {
			if (this.ruleForm.recommendPhone.length==11) {
				this.getRecommendData();
			} else {
				if(this.ruleForm.recommendPhone.length==0){
					this.$message({showClose: true,
						type: 'error',
						message: '请输入推荐人手机号'
					});
				}else{
					this.$message({showClose: true,
						type: 'error',
						message: '请输入正确的手机号'
					});
				}
			}
		},
		async getRecommendData() {
			try {
				let data = {
					Phone: this.ruleForm.recommendPhone
				};
				let result = await recommendmemberinfobyphone(data);
				if (result.IsSuccess) {
					if (result.Result.Id > 0) {
						this.isShowRecommend = true;
						this.isShowRecTip = false;
						this.recommendData = result.Result;
						// console.log(this.recommendData);
					} else {
						this.isShowRecommend = false;
						this.isShowRecTip = true;
					}
				}
			} catch (e) {
				//TODO handle the exception
			}
		},
		lockSearch() {
			if (this.ruleForm.lockPhone) {
				this.getLockData();
			} else {
				this.$message({showClose: true,
					type: 'error',
					message: '请输入锁粉店员手机号'
				});
			}
		},
		async getLockData() {
			try {
				let data = {
					Phone: this.ruleForm.lockPhone
				};

				let result = await employeeinfobyphone(data);
				if (result.IsSuccess) {
					if (result.Result.Id > 0) {
						this.isShowLock = true;
						this.isShowLockTip = false;
						this.lockData = result.Result;
						console.log(this.lockData);
					} else {
						this.isShowLock = false;
						this.isShowLockTip = true;
					}
				}
			} catch (e) {
				//TODO handle the exception
			}
		},
		changeRecommendPhone(value) {
			console.log('---------');
			console.log(value);
		},
		changeLockPhone(value) {}
	}
};
</script>
<style lang="less" scoped>
.content {
	::v-deep .el-input {
		width: 400px !important;
	}
}
.referrer-card {
	width: 260px;
	height: 60px;
	border: 1px solid #67c23a;
	margin-top: 10px;
	border-radius: 1px;
	position: relative;
	padding: 5px;
	display: flex;
	flex-direction: row;
	line-height: 20px;
	align-items: center;
	.check {
		position: absolute;
		right: -1px;
		top: -4px;
	}
	::v-deep .el-checkbox {
		width: 14px;
		height: 14px;
	}
	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #67c23a;
		border-color: #67c23a;
	}
	::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
		border-color: #67c23a;
	}
	::v-deep .el-checkbox__inner:hover {
		border-color: #67c23a;
	}
	.referrer-img {
		width: 50px;
		height: 50px;
		border-radius: 100%;
		object-fit: cover;
		margin: 0 10px 0 10px;
	}
	.referrer-text {
		display: inline-block;
		width: 165px;
		overflow: hidden;
	}
	.name {
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 14px;
		color: #909399;
	}
}
</style>
